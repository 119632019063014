.engagements {
    margin-top: 15px;

    .card-body {
        overflow: unset;
    }

    input.filter,
    select.filter {
        font-size: 14px;
        font-family: 'SegoeUI';
    }

    .header-vetrical-align-top {
        th {
            vertical-align: top;
        }
    }

    .react-bootstrap-table-pagination {
        flex-wrap: nowrap;
    }

    .react-bootstrap-table {
        overflow: auto;
        width: 100%;

        .id-column-header {
            width: auto !important;
        }

        .dropdown-column-header {
            width: 15% !important;
        }

        .status-column-header {
            min-width: 130px;
            max-width: 140px
        }

        .type-column-header {
            min-width: 120px;
            max-width: 130px;
        }

        .deadline-event-column-header {
            min-width: 130px;
            max-width: 140px;
        }

        .name-column-header {
            width: 25% !important;
        }

        .username-column-header {
            @extend .name-column-header;
            min-width: 215px;
        }

        .practice-column-header {
            @extend .name-column-header;
            min-width: 180px;
        }

        .stage-event-column-header {
            min-width: 195px;
        }

        .actions-column-header {
            min-width: 270px;
        }

        .action-column-cell {
            min-width: 295px;
        }

        >table>thead>tr>th input.filter,
        >table>thead>tr>th select.filter {
            height: 30px;
        }

        >table>thead>tr>th .date-filter {
            flex-wrap: nowrap;
            padding: 0px;

            label.filter-label {
                display: none !important;
            }

            label {
                margin-bottom: 0;
            }

            .date-filter-input {
                width: 90%;
                margin-top: 0;
                margin-left: 0;

                color: lightgrey;
                font-style: italic;
                font-size: 14px;
                font-family: 'SegoeUI';
            }
        }
    }

    .right-btn {
        float: right;
    }

    .engagement-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .engagement-btn {
            font-size: 12px;
            padding: 3px 6px
        }
    }

    .react-bootstrap-table::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #c1c1c157;
    }

    .react-bootstrap-table::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    .react-bootstrap-table-pagination {
        margin-top: 0.25em;
    }
}